var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"id":_vm.id,"headers":_vm.headers,"items":_vm.itemsToDisplay},scopedSlots:_vm._u([{key:"item.employeeName",fn:function(ref){
var item = ref.item;
return [(_vm.showReviewLink)?[(_vm.showForCurrentUser(item))?_c('router-link',{attrs:{"to":{ name: _vm.reviewLink(item), params: { employeeId:_vm.currentUser.employeeId , id: item.id }}}},[_vm._v(" "+_vm._s(item.employeeName)+" ")]):_c('router-link',{attrs:{"to":{ name: _vm.reviewLink(item), params: { employeeId:item.employeeId , id: item.id }}}},[_vm._v(" "+_vm._s(item.employeeName)+" ")])]:[(_vm.showForCurrentUser(item))?_c('router-link',{attrs:{"to":{ name: _vm.viewLink(item), params: { employeeId:_vm.currentUser.employeeId , id: item.id }}}},[_vm._v(" "+_vm._s(item.employeeName)+" ")]):_c('router-link',{attrs:{"to":{ name: _vm.viewLink(item), params: { employeeId:item.employeeId , id: item.id }}}},[_vm._v(" "+_vm._s(item.employeeName)+" ")])]]}},{key:"item.leaveType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLeaveTypeDescription")(item.requestType))+" ")]}},{key:"item.requestDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.requestDate))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.startDate))+" ")]}},{key:"item.resumptionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateString")(item.resumptionDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toRequestStatusDescription")(item.status))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"action-btn mr-2 font-weight-bold h5",style:({visibility: _vm.canEditRequest(item) ? 'visible' : 'hidden'}),on:{"click":function($event){return _vm.editRequest(item)}}},[_vm._v("✎")]),(_vm.canApproveRequest(item))?_c('span',{staticClass:"action-btn mr-2 font-weight-bold h5",attrs:{"title":_vm.getApprovalActionTitle(item)},on:{"click":function($event){return _vm.approveRequest(item)}}},[_vm._v("✓")]):_vm._e(),(_vm.canCancelRequest(item))?_c('span',{staticClass:"action-btn font-weight-bold h5 ml-2",attrs:{"title":"Cancel request"},on:{"click":function($event){return _vm.cancelRequest(item)}}},[_vm._v("✖")]):(_vm.canRejectRequest(item))?_c('span',{staticClass:"action-btn font-weight-bold h5 ml-2",attrs:{"title":"Reject request"},on:{"click":function($event){return _vm.rejectRequest(item)}}},[_vm._v("✖")]):_vm._e(),(_vm.canReturnRequest(item))?_c('span',{staticClass:"action-btn font-weight-bold h5 ml-2",attrs:{"title":"Return request"},on:{"click":function($event){return _vm.returnRequest(item)}}},[_vm._v("⏎")]):_vm._e(),(_vm.canProcessRequest(item))?_c('span',{staticClass:"action-btn font-weight-bold h5 ml-2",attrs:{"title":"Process payment"},on:{"click":function($event){return _vm.processRequest(item)}}},[_vm._v(_vm._s(item.allowance.allowanceDisbursementMode == _vm.Enums.AllowanceDisbursementModes.USDAccountDeposit.value ? '$' : '₦'))]):_vm._e()]}}])}),_c('app-pager',{attrs:{"totalNumberOfItems":_vm.numberOfItems,"numberOfItemsPerPage":_vm.numberOfItemsPerPage,"numberOfPagers":_vm.numberOfPagers,"currentPage":_vm.currentPage},on:{"paginate":_vm.goToPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }