<template>
  <div>
    <data-table :id='id' :headers='headers' :items='itemsToDisplay'>
      <template v-slot:item.employeeName="{ item }">
        <template v-if="showReviewLink">
          <router-link v-if="showForCurrentUser(item)" :to="{ name: reviewLink(item), params: { employeeId:currentUser.employeeId , id: item.id }}">
            {{ item.employeeName }}
          </router-link>
          <router-link v-else :to="{ name: reviewLink(item), params: { employeeId:item.employeeId , id: item.id }}">
            {{ item.employeeName }}
          </router-link>
        </template>
        <template v-else>
          <router-link v-if="showForCurrentUser(item)" :to="{ name: viewLink(item), params: { employeeId:currentUser.employeeId , id: item.id }}">
            {{ item.employeeName }}
          </router-link>
          <router-link v-else :to="{ name: viewLink(item), params: { employeeId:item.employeeId , id: item.id }}">
            {{ item.employeeName }}
          </router-link>
        </template>
      </template>
      <template v-slot:item.leaveType="{ item }">
        {{ item.requestType | toLeaveTypeDescription }}
      </template>
      <template v-slot:item.requestDate="{ item }">
        {{ item.requestDate | toShortDateString }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ item.startDate | toShortDateString }}
      </template>
      <template v-slot:item.resumptionDate="{ item }">
        {{ item.resumptionDate | toShortDateString }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status | toRequestStatusDescription }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="action-btn mr-2 font-weight-bold h5" :style="{visibility: canEditRequest(item) ? 'visible' : 'hidden'}" @click="editRequest(item)">&#x270E;</span>
        <span class="action-btn mr-2 font-weight-bold h5" v-if="canApproveRequest(item)" @click="approveRequest(item)" :title=getApprovalActionTitle(item)>&#x2713;</span>
        <span class="action-btn font-weight-bold h5 ml-2" v-if="canCancelRequest(item)" @click="cancelRequest(item)" title="Cancel request">&#x2716;</span>
        <span class="action-btn font-weight-bold h5 ml-2" v-else-if="canRejectRequest(item)" @click="rejectRequest(item)" title="Reject request">&#x2716;</span>
        <span class="action-btn font-weight-bold h5 ml-2" v-if="canReturnRequest(item)" @click="returnRequest(item)" title="Return request">&#x23CE;</span>
        <span class="action-btn font-weight-bold h5 ml-2" v-if="canProcessRequest(item)" @click="processRequest(item)" title="Process payment">{{ item.allowance.allowanceDisbursementMode == Enums.AllowanceDisbursementModes.USDAccountDeposit.value ? '&#36' : '&#8358;'  }}</span>
      </template>
    </data-table>
    <app-pager :totalNumberOfItems="numberOfItems" :numberOfItemsPerPage=numberOfItemsPerPage :numberOfPagers="numberOfPagers" :currentPage="currentPage" @paginate="goToPage" />
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
  import { mapState } from 'vuex'

  import eventBus from '@/utils/eventBus'
  import { EDIT_REQUEST_EVENT, APPROVE_REQUEST_EVENT, REJECT_REQUEST_EVENT, RETURN_REQUEST_EVENT, CANCEL_REQUEST_EVENT, PROCESS_REQUEST_EVENT } from '@/utils/constants'

  import Enums from '@/utils/enums'
  import { toRequestStatusDescription, toLeaveTypeDescription } from '@/filters/enum'
  import { toShortDateString } from '@/filters'

  import DataTable from '@/components/ui/DataTable'
  import AppPager from '@/components/ui/AppPager'

  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'

  export default {
    name: 'request-list',
    mixins: [LeaveRequestActionsMixin],
    components: {
      DataTable,
      AppPager
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'requestList'
      },
      requests: {
        type: Array,
        required: true
      },
      employeeId: {
        type: String,
        required: false,
        default: ''
      },
      showReviewLink: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data: () => ({
      headers: [
        {
          name: 'employeeName',
          title: 'employee name'
        },
        {
          name: 'requestDate',
          title: 'request date'
        },
        {
          name: 'leaveType',
          title: 'leave type'
        },
        {
          name: 'startDate',
          title: 'start date'
        },
        {
          name: 'resumptionDate',
          title: 'resumption date'
        },
        {
          name: 'duration',
          title: 'days'
        },
        {
          name: 'status',
          title: 'status'
        },
        {
          name: 'actions',
          title: ''
        }
      ],
      numberOfItemsPerPage: 10,
      numberOfPagers: 7,
      currentPage: 1,
      Enums: Enums
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
      }),
      numberOfItems() {
        return this.requests.length
      },
      itemsToDisplay() {
        return this.requests.slice((this.currentPage - 1) * this.numberOfItemsPerPage, this.numberOfItemsPerPage * this.currentPage)
      }
    },
    filters: {
      toRequestStatusDescription,
      toLeaveTypeDescription,
      toShortDateString
    },
    watch: {
      itemsToDisplay(value) {
        if (!value.length) {
          this.currentPage = 1
        }
      }
    },
    methods: {
      showForCurrentUser(request) {
        switch (request.requestType) {
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return true
          default:
            return false
        }
      },
      reviewLink(request) {
        switch (request.requestType) {
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
            return 'reviewChangeRequest'
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return ''
          default:
            return `review${request.requestType}Request`
        }
      },
      viewLink(request) {
        switch (request.requestType) {
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return 'viewChangeRequest'
          default:
            return `view${request.requestType}Request`
        }
      },
      //showReviewLink(request) {
      //  return this.canEditRequest(request) || this.canCancelRequest(request) || this.canApproveRequest(request) || this.canRejectRequest(request) || this.canReturnRequest(request)
      //},
      getApprovalActionTitle(request) {
        switch (request.requestType.toLowerCase()) {
          case Enums.RequestType.StudyLeave.value.toLowerCase():
          case Enums.RequestType.LeaveOfAbsence.value.toLowerCase():
            if (request.status !== Enums.RequestStatus.PendingHRDirector.value) {
              return 'Mark request as reviewed' 
            }
            break
        }
        return 'Approve request'
      },
      editRequest(request) {
        eventBus.$emit(EDIT_REQUEST_EVENT, request)
      },
      cancelRequest(request) {
        eventBus.$emit(CANCEL_REQUEST_EVENT, request, Enums.Actions.Cancel, Enums.RequestStatus.Cancelled)
      },
      approveRequest(request) {
        eventBus.$emit(APPROVE_REQUEST_EVENT, request, Enums.Actions.Approve, Enums.RequestStatus.Approved)
      },
      rejectRequest(request) {
        eventBus.$emit(REJECT_REQUEST_EVENT, request, Enums.Actions.Reject, Enums.RequestStatus.Rejected)
      },
      returnRequest(request) {
        eventBus.$emit(RETURN_REQUEST_EVENT, request, Enums.Actions.Return, Enums.RequestStatus.Returned)
      },
      processRequest(request) {
        eventBus.$emit(PROCESS_REQUEST_EVENT, request, Enums.Actions.Process, Enums.RequestStatus.Processed)
      },
      goToPage(page) {
        this.currentPage = page
      }
    }
  }

</script>
